<template>
  <div v-if="isPermission">
    <CCard>
      <CCardBody>
        <div class="d-flex">
          <div class="col-md-2 col-sm-3 col-3 text-right">
              <CButton id="memberTransactionBackButton" block color="light" @click="goback">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
          </div>
        </div>
        <h4 id="memberTransactionTitle" class="text-center font-weight-normal">
          {{ $t('purchaseDetail') }}
        </h4>
        <!-- <CTabs justified fill class="mt-4">
          <CTab title="รายการที่สำเร็จ" active> -->
        <CCardBody id="memberTransactionTableContainer">
          <CDataTable
            id="memberTransactionTable"
            style="white-space:nowrap;"
            :items="items"
            :fields="fields"
            hover
            border
            clickableRows
            @row-clicked="toggleDetails"
          > 
          <template #receiptNumber="{ item, index }"  >
                    <td  :id=" `memberDetailTableRow-${index}`">
                      {{ item.receiptNumber }}
                    </td>
                  </template>
            <template #details="{ item, index }">
              <CCollapse :show="details.includes(item)" :id="`memberTransactionDetailsCollapse-${index}`">
                <CCardBody :id="`memberTransactionDetailsCard-${index}`">
                  <p class="text-left">
                    <b>{{ $t('paymentDescription') }}</b>
                  </p>
                  <CDataTable
                   :id="`memberTransactionDetailsTable-${index}`"
                    style="white-space:nowrap;"
                    :items="itemsDetail(index)"
                    :fields="fieldsDetail"
                    hover
                    border
                    striped
                  >
                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                  </CDataTable>
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
        <!--  </CTab>
          <CTab title="รายการที่เปิดอยู่"> </CTab>
        </CTabs> -->
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data id="memberTransactionNoPermission"></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import moment from 'moment'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  data() {
    return {
      lastPurchase: [],
      grandTotal: 0,
      bill: 0,
      details: [],
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    objectId() {
      return this.$route.params.objectId
    },
    fields() {
      return [
        { key: 'dateTime', label: this.$i18n.t('date'), _classes: 'text-dark text-left font-weight-normal' },
        {
          key: 'receiptNumber',
          label: this.$i18n.t('receiptNumber'),
          _classes: 'text-dark text-left font-weight-normal',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-dark text-right font-weight-normal',
        },
        {
          key: 'netTotalIncVAT',
          label: this.$i18n.t('netTotalIncVAT'),
          _classes: 'text-dark text-right font-weight-normal',
        },
        {
          key: 'netTotalNonVAT',
          label: this.$i18n.t('netTotalNonVAT'),
          _classes: 'text-dark text-right font-weight-normal',
        },
        {
          key: 'netAmountVATTotal',
          label: this.$i18n.t('netAmountVATTotal'),
          _classes: 'text-dark text-right font-weight-normal',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-dark text-right font-weight-normal',
        },
      ]
    },
    items() {
      const data = this.lastPurchase || []
      let info = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const grandTotal = util.convertCurrency(item.grandTotal || 0)
        const netAmountVATTotal = util.convertCurrency(item.netAmountVATTotal || 0)
        const netTotalIncVAT = util.convertCurrency(item.netTotalIncVAT || 0)
        const netTotalNonVAT = util.convertCurrency(item.netTotalNonVAT || 0)
        const vatAmount = util.convertCurrency(item.vatAmount || 0)

        let _classes = 'text-dark'
        if (data[i].receiptStatus === 'V') {
            _classes = 'text-danger'
        }
        info.push({
          dateTime: moment(String(data[i].created_at)).format('DD/MM/YYYY HH:mm:ss'),
          receiptNumber: item.receiptNumber || '#',
          grandTotal: grandTotal,
          receiptItems: item.receiptItems || [],
          netTotalIncVAT: netTotalIncVAT,
          netTotalNonVAT: netTotalNonVAT,
          netAmountVATTotal: netAmountVATTotal,
          vatAmount: vatAmount,
          receiptStatus: item.receiptStatus,
          _classes: _classes,
          receiptObjectId: item.objectId,
        })
      }
      return info
    },
    fieldsDetail() {
      return [
        { key: 'pluCode', label: this.$i18n.t('pluCode') , _classes: 'text-dark font-weight-normal',},
        {
          key: 'pluName',
          label: this.$i18n.t('productName'),
          _classes: 'text-left text-dark font-weight-normal',
        }, //
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'discountTotal',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
  },
  created() {
    this.getMemberDashboard()
  },
  methods: {
    ...util,
    convertCurrency(number) {
      return util.convertCurrency(number)
    },
    showDate(createdAt) {
      return moment(createdAt).format('DD/MM/YYYY HH:mm')
    },
    getMemberDashboard() {
      const uid = this.uid
      let params = {
        shopObjectId: this.shopObjectId,
        memberObjectId: this.objectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }

      pos({
        method: 'get',
        url: '/api/v1.0/' + uid + '/Receipt/memberdashboard/data',
        params: params,
        headers: headers,
      })
        .then((res) => {
          let purchase = res.data.data
          this.frequencyPurchase = purchase.frequencyPurchase
          this.lastPurchase = purchase.lastPurchase
          this.grandTotal = purchase.grandTotal
          this.bill = purchase.bill
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item) {
      const position = this.details.indexOf(item)

      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(item)
    },
    itemsDetail(index) {
      const data = this.lastPurchase[index].receiptItems
      let info = []
      for (let i = 0; i < data.length; i++) {
        let discountTotal = data[i].discountAmount + data[i].discountBillPerQty
        info.push({
          pluCode: data[i].pluCode,
          pluName: data[i].pluName,
          quantitySKU: data[i].quantitySKU,
          price: util.convertCurrency(data[i].price),
          netAmount: util.convertCurrency(data[i].totalAmount),
          discountTotal: util.convertCurrency(discountTotal),
          totalAmount: util.convertCurrency(data[i].netAmount),
        })
      }
      return info
    },
    goback() {
      this.$router.push('/member/' + this.objectId + '/getdetail')
    },
  },
}
</script>
